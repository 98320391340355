/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Input2, DropDown2, Editor } from "components";
import { useInput } from "hooks";
import { getTransformGroupByIdApi, updateTransformGroupApi } from "api";
import { useMutation, useQuery } from "react-query";
import { isJSON } from "utils";

export const EditGroup = ({
  onUpdate = () => {},
  groupId,
  onCancel = () => {},
}) => {
  const outputExtension = useInput("csv");
  const columnOrdering = useInput("");
  const name = useInput("");
  const block_order = useInput([]);
  const updateMutation = useMutation((data) => updateTransformGroupApi(data));

  const handleUpdate = async () => {
    if (name.value) {
      await updateMutation.mutateAsync({
        name: name.value,
        output_type: outputExtension.value,
        transform_group_id: groupId,
        key_order: JSON.parse(columnOrdering.value),
        block_order: block_order.value
      });
      onUpdate(groupId);
    }
  };

  const { data: groupBlocksData } = useQuery(
    ["createTransformblocksbyId", groupId],
    () => getTransformGroupByIdApi(groupId),
    {
      initialData: { name: "", output_type: "" },
      keepPreviousData: true,
      enabled: !!groupId,
    }
  );

  useEffect(() => {
    name.setValue(groupBlocksData?.name);
    outputExtension.setValue(groupBlocksData?.output_type);
    block_order.setValue(groupBlocksData?.block_order);
    if(groupBlocksData?.key_order && groupBlocksData?.key_order.length > 0) {
      columnOrdering.setValue(JSON.stringify(groupBlocksData?.key_order));
    }
    return () => {};
  }, [groupBlocksData]);

  return (
    <CreateContainer className="row">
      <div className="col-6">
        <Heading>Edit {groupBlocksData.name}</Heading>
        <Input2
          label="Group Name"
          name="username"
          className="mt-3"
          placeholder="Enter Name"
          {...name}
        />

        <DropDown2
          label="Output Extension"
          className="mt-3"
          idField="retailer_id"
          data={[
            { id: "json", title: "json" },
            { id: "csv", title: "csv" },
          ]}
          placeholder="Select an output extension"
          {...outputExtension}
        />
        <div className="d-flex mt-4">
          <Button
            size="small"
            className="ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            className="ml-2"
            width="120px"
            buttonTheme="dark"
            onClick={() => handleUpdate(groupId)}
            disabled={
              !name.value ||
              !outputExtension.value ||
              (columnOrdering.value && !isJSON(columnOrdering.value))
            }
            isLoading={updateMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="col-6">
        <Heading>OUTPUT COLUMN ORDERING</Heading>
        <Editor
          label="Filter Columns"
          name="username"
          className="mt-3"
          placeholder="[ 'title', 'url' ]"
          style={{ maxHeight: 560, fontSize: 16 }}
          {...columnOrdering}
        />
      </div>
    </CreateContainer>
  );
};

const CreateContainer = styled.div`
  flex: 1;
`;

const Heading = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 20px;
`;
